<template>
  <div class="not-found">
    <div class="icon"></div>
    <h1>¡Ups!</h1>
    <h2>No encontramos nada por aquí</h2>
  </div>
</template>
<script>
export default{
  name: 'NotFound'
}
</script>
<style scoped>
.not-found{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  }

  .not-found h1{
    color: black;
    text-align: center;
  }

  .icon {
    background: #aaaa;
    mask: url("/assets/img/not-found.svg");
    mask-size: 80px;
    width: 80px;
    height: 80px;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  .not-found h2, .icon{
    margin-bottom: 24px;
  }

</style>