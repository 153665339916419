<template>
    <section name="proximamente">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">Próximamente</h2>
                <dropdown class="my-dropdown-toggle"
                    :options="dropdownOptions" 
                    :selected="dropdownSelected()" 
                    v-on:updateOption="OnSelect" 
                    :placeholder="'Todas'"
                    :closeOnOutsideClick="true">
                </dropdown>
            </div>
            <div class="section-content" v-if="isLoading">
                <div class="col l2 m3 s4" v-for="(n,index) in 18" :key="index">
                    <MovieItem :showPlay="false" :showDateTag="true" :showTitle="true" />
                </div>
            </div> 
            <div class="section-content" v-else-if="movies.data && movies.data.length > 0">   
                <div class="col l2 m3 s4" v-for="movie in movies.data" :key="movie.id">
                    <MovieItem :movie="movie" :showPlay="false" :showDateTag="true" :showTitle="true" />
                </div>
                <Spinner v-if="isLoadingMore"/>
                <div class="bottom-container" v-if="loadMoreStatus && !isLoadingMore"><a @click="loadMore()" class="load-more">Cargar Más</a></div>
            </div>
            <div class="section-content" v-else>
                <NotFound/>
            </div>
        </div>
    </section>
</template>

<script>
import MovieItem from '@/components/MovieItem.vue'
import Spinner from '@/components/Spinner.vue'
import dropdown from 'vue-dropdowns'
import router from '../router'
import NotFound from '@/components/NotFound.vue'

export default{
    name: 'Proximamente',
    components:{
        MovieItem,
        Spinner,
        dropdown,
        NotFound
    },
    data() {
        return {
            movies: [],
            isLoading: false,
            page: 1,
            loadMoreStatus: false,
            isLoadingMore: false,
            dropdownOptions: [{name: 'Todas'}],
            currentPath: null,
            newPath: null
        }
    },
    mounted() {
        (async () => {
            const category = this.$route.params.category

            this.setHeader('page', {
                titulo: 'Próximamente'
            })

            this.isLoading = true
            this.movies.data = []
            this.movies.meta = []

            this.currentPath = window.location.pathname+window.location.search

            // get categories
            let response = await this.getItems('generos')
            if( response.data && response.data.length > 0){
                response.data.forEach(item => {
                    this.dropdownOptions.push({name: item.nombre})
                });
            }

            this.loadItems()
        })()
    },
    methods:{
        loadItems: async function(){
            // get thu
			const thisThu = this.getThu('this')

            //get previous movies
            let previousMovies = this.movies.data

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            let filter = 'page='+this.page+'&filter[estado][eq]=publicado&filter[fecha_local][gt]='+thisThu+'&sort=fecha_local,-visualizaciones&meta=total_count,result_count,filter_count';

            if(params.filter && params.filter != 'Todas'){
                filter+= '&filter[genero.generos_id.nombre][eq]='+params.filter
            }
            
            this.movies = await this.getItems('peliculas', 18, filter)

            this.movies.data = previousMovies.concat(this.movies.data)
                
            if( (this.movies.meta.filter_count > this.movies.data.length && params.filter) || (this.movies.meta.filter_count > this.movies.data.length  && typeof(params.filter) == 'undefined') ){
                this.page++
                this.loadMoreStatus = true
            }else{
                this.loadMoreStatus = false
            }
            
            this.isLoading = false
        },
        loadMore: async function(){
            this.isLoadingMore = true
            await this.loadItems()
            this.isLoadingMore = false
        },
        OnSelect: function(payload) {
           this.newPath = '/proximamente?filter='+encodeURIComponent(payload.name)

            if( this.newPath != this.currentPath){
                this.page= 1
                this.currentPath = this.newPath
                this.isLoading = true
                router.push('/proximamente?filter='+payload.name)
            }
        },
        dropdownSelected: function(){
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            if(params.filter){
                return { name: params.filter }
            }else{
                return { name: 'Todas' }
            }
        }
    },
    watch:{
        $route (to, from){
            this.movies.data = []
            this.loadItems()
        }
    }
}
</script>

<style scoped>

</style>
